var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"table-indicadores",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.items,"options":{ itemsPerPage: 10 },"headerProps":{ sortByText: 'Ordenar Por' },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [(_vm.traducaoIndicadores[h.value] != null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text.replaceAll("_", " ")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.traducaoIndicadores[h.value]))])]):_c('span',[_vm._v(_vm._s(h.text.replaceAll("_", " ")))])]}}}),{key:"item.recebidas",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.recebidas))+" ")])]}},{key:"item.qrt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.qrt))+" ")])]}},{key:"item.prp",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.prp != "-" ? item.prp : "-"))+" "+_vm._s(item.prp != "-" ? " %" : "")+" ")])]}},{key:"item.pri",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.pri != "-" ? item.pri : "-"))+" "+_vm._s(item.pri != "-" ? " %" : "")+" ")])]}},{key:"item.prazo_medio_total_reclamacoes_encerradas",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.prazo_medio_total_reclamacoes_encerradas))+" ")])]}},{key:"item.total_reclamacao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.total_reclamacao))+" ")])]}},{key:"item.prazo_medio_total_reclamacoes_improcedentes_encerradas",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.prazo_medio_total_reclamacoes_improcedentes_encerradas))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }