<template>
  <div>
    <v-data-table
      class="table-indicadores"
      multi-sort
      dense
      :headers="headers"
      :items="items"
      :options="{ itemsPerPage: 10 }"
      :headerProps="{ sortByText: 'Ordenar Por' }"
      no-data-text="Nenhum registro disponível"
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="10"
    >
    <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip bottom v-if="traducaoIndicadores[h.value] != null">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text.replaceAll("_", " ") }}</span>
          </template>
          <span>{{ traducaoIndicadores[h.value] }}</span>
        </v-tooltip>
        <span v-else>{{ h.text.replaceAll("_", " ") }}</span>
      </template>
      <!-- <template v-slot:[`item.empresa`]="{ item }">
        {{ item.empresa.name }}
      </template> -->
      <template v-slot:[`item.recebidas`]="{ item }">
        <span>
          {{ item.recebidas | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.qrt`]="{ item }">
        <span>
          {{ item.qrt | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.prp`]="{ item }">
        <span>
          {{
            item.prp != "-" ? item.prp : "-" | parseNumberToFloatBrIgnoringNaN
          }}
          {{ item.prp != "-" ? " %" : "" }}
        </span>
      </template>
      <template v-slot:[`item.pri`]="{ item }">
        <span>
          {{
            item.pri != "-" ? item.pri : "-" | parseNumberToFloatBrIgnoringNaN
          }}
          {{ item.pri != "-" ? " %" : "" }}
        </span>
      </template>
      <template v-slot:[`item.prazo_medio_total_reclamacoes_encerradas`]="{ item }">
        <span>
          {{ item.prazo_medio_total_reclamacoes_encerradas | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.total_reclamacao`]="{ item }">
        <span>
          {{ item.total_reclamacao | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.prazo_medio_total_reclamacoes_improcedentes_encerradas`]="{ item }">
        <span>
          {{ item.prazo_medio_total_reclamacoes_improcedentes_encerradas | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import DashboardAnexo1Service from "@/services/DashboardAnexo1Service.js";

  export default {
    name: "DashboardAnexo1Geral",
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: () => {},
      },
      canaisSelecionados: {
        type: Array,
        default: () => []
      },
      niveisSelecionados: {
        type: Array,
        default: () => []
      },
      tipologiasSelecionadas: {
        type: Array,
        default: () => []
      },
      subtipologiasSelecionadas: {
        type: Array,
        default: () => []
      },
    },
    data: () => ({
      companies: [],
      dados: {},
    }),
    computed: {
      formattedCompetencia() {
        const [year, month] = this.competencia.split("-");

        return `${month}/${year}`;
      },
      kpis() {
        return this.dados.length > 0 ? Object.keys(this.dados[0]) : [];
      },
      headers() {
        let headers = [];
        this.kpis.forEach((kpi) =>
          headers.push({
            text: kpi.toUpperCase(),
            value: kpi.toLowerCase(),
          })
        );
        return headers;
      },
      items() {
        let items = [];
        if (this.dados.length > 0) {
          items = this.dados;
        }
        return items;
      },
    },
    mounted() {
      this.getDados();
    },
    methods: {
      getDados() {
        // Apenas atualiza os dados se for a visão selecionada
        if (this.activatedTab !== 0) return;

        this.$emit("updateLoadingDados", true);

        DashboardAnexo1Service.getDadosIndicadoresTodasEmpresas(
          this.competenciaDe,
          this.competenciaAte,
          this.canaisSelecionados.join(","),
          this.niveisSelecionados.join(","),
          this.tipologiasSelecionadas.join(","),
          this.subtipologiasSelecionadas.join(",")
        )
          .then((res) => {
            this.dados = res;
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados da visão Geral.", "", {
              position: "topRight",
            });
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      }
    },
  };
</script>

<style>
  .table-indicadores {
    margin: 10px 0;
    padding: 10px;
  }

  .table-indicadores table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
    text-align: end;
  }

  .table-indicadores table thead tr th {
    font-size: 12px;
    height: 25px;
    text-align: end !important;
    font-weight: 500;
  }

  .v-card-title-icon {
    position: relative;
    top: -40px;
    transition: 0.5s ease;
  }
</style>
