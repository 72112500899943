import APIService from "./APIService";

export default {
  getDadosIndicadoresTodasEmpresas(
    competenciaDe,
    competenciaAte,
    canaisSelecionados,
    niveisSelecionados,
    tipologiasSelecionadas,
    subtipologiaSelecionadas
  ) {
    const canais = encodeURI(canaisSelecionados);
    const niveis = encodeURI(niveisSelecionados);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-anexo-1/get-dados-indicadores-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&canais=${canais}&niveis=${niveis}&tipologias=${tipologiasSelecionadas}&subtipologias=${subtipologiaSelecionadas}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresTotalizados(
    competenciaDe,
    competenciaAte,
    canaisSelecionados,
    niveisSelecionados,
    tipologiasSelecionadas,
    subtipologiaSelecionadas,
    regional
  ) {
    const canais = encodeURI(canaisSelecionados);
    const niveis = encodeURI(niveisSelecionados);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-anexo-1/get-dados-indicadores-totalizados?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&canais=${canais}&niveis=${niveis}&tipologias=${tipologiasSelecionadas}&subtipologias=${subtipologiaSelecionadas}&regional=${regional}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresPorCompetencia(
    competenciaDe,
    competenciaAte,
    regionalId = 0,
    canaisSelecionados,
    niveisSelecionados,
    tipologiasSelecionadas,
    subtipologiaSelecionadas
  ) {
    const canais = encodeURI(canaisSelecionados);
    const niveis = encodeURI(niveisSelecionados);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-anexo-1/get-dados-indicadores-por-competencia?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&canais=${canais}&niveis=${niveis}&tipologias=${tipologiasSelecionadas}&subtipologias=${subtipologiaSelecionadas}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresPorCanal(
    competenciaDe,
    competenciaAte,
    regionalId = 0,
    canaisSelecionados,
    niveisSelecionados,
    tipologiasSelecionadas,
    subtipologiaSelecionadas
  ) {
    const canais = encodeURI(canaisSelecionados);
    const niveis = encodeURI(niveisSelecionados);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-anexo-1/get-dados-indicadores-por-canal?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&canais=${canais}&niveis=${niveis}&tipologias=${tipologiasSelecionadas}&subtipologias=${subtipologiaSelecionadas}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresPorTipologia(
    competenciaDe,
    competenciaAte,
    regionalId = 0,
    canaisSelecionados,
    niveisSelecionados,
    tipologiasSelecionadas,
    subtipologiaSelecionadas
  ) {
    const canais = encodeURI(canaisSelecionados);
    const niveis = encodeURI(niveisSelecionados);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-anexo-1/get-dados-indicadores-por-tipologia?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&canais=${canais}&niveis=${niveis}&tipologias=${tipologiasSelecionadas}&subtipologias=${subtipologiaSelecionadas}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresPorSubtipologia(
    competenciaDe,
    competenciaAte,
    regionalId = 0,
    canaisSelecionados,
    niveisSelecionados,
    tipologiasSelecionadas,
    subtipologiaSelecionadas
  ) {
    const canais = encodeURI(canaisSelecionados);
    const niveis = encodeURI(niveisSelecionados);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-anexo-1/get-dados-indicadores-por-subtipologia?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&canais=${canais}&niveis=${niveis}&tipologias=${tipologiasSelecionadas}&subtipologias=${subtipologiaSelecionadas}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosIndicadoresPorMunicipio(
    competenciaDe,
    competenciaAte,
    regionalId,
    canaisSelecionados,
    niveisSelecionados,
    tipologiasSelecionadas,
    subtipologiaSelecionadas
  ) {
    const canais = encodeURI(canaisSelecionados);
    const niveis = encodeURI(niveisSelecionados);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-anexo-1/get-dados-indicadores-por-municipio?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&canais=${canais}&niveis=${niveis}&tipologias=${tipologiasSelecionadas}&subtipologias=${subtipologiaSelecionadas}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getDadosTotaisIndicadores(
    competenciaDe,
    competenciaAte,
    regionalId,
    canaisSelecionados,
    niveisSelecionados,
    tipologiasSelecionadas,
    subtipologiaSelecionadas
  ) {
    const canais = encodeURI(canaisSelecionados);
    const niveis = encodeURI(niveisSelecionados);

    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `dashboard-anexo-1/get-dados-totais-indicadores?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&regional_id=${regionalId}&canais=${canais}&niveis=${niveis}&tipologias=${tipologiasSelecionadas}&subtipologias=${subtipologiaSelecionadas}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
};
